<template>
    <div class="edit-form">
        <h5 class="p-d-inline p-mb-3 p-pt-0 edit-form-title">{{ formTitle }}</h5>
        <TabView class="edit-form__tabs">
            <TabPanel v-for="(tab, index) in tabs" :active.sync="activeTabIdx[index]" :key="index" :header="tab.label"> </TabPanel>
        </TabView>
        <form v-if="activeTabIdx[0]" class="my-form p-pt-4 p-pb-4 p-pl-4">
            <div class="p-d-flex p-flex-column">
                <div class="p-d-flex p-flex-column edit-form__input">
                    <label for="id">Уникальный идентификатор в системе*</label>
                    <InputText id="id" name="id" v-model.trim="v$.roleIdentificator.$model" placeholder="" :class="v$.roleIdentificator.$invalid && submitted ? 'p-error' : ''" :disabled="isEditMode" />
                    <small v-show="submitted" v-for="error in showErrors(v$.roleIdentificator)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                </div>
                <div class="edit-form__input">
                    <label for="role-desc">Описание</label>
                    <Textarea v-model.trim="roleDesc" id="role-desc" auto-resize cols="60" rows="4" />
                </div>
            </div>
        </form>
        <div v-if="activeTabIdx[1]">
            <DataTable
                :value="allPermissions"
                dataKey="id"
                class="p-datatable-striped role-list"
                stripedRows
                :scrollable="true"
                scroll-height="calc(100vh - 405px)"
                :paginator="true"
                :rows="20"
                :rowsPerPageOptions="rowsPerPageOptions"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate=""
                :totalRecords="totalRecords"
            >
                <Column header="" column-key="id" headerStyle="width: 5%">
                    <template #header>
                        <Checkbox v-model="allPermissionsProp" binary />
                    </template>
                    <template #body="{ data }">
                        <input type="hidden" :value="selectedPermissionIds[data.id]" />
                        <div class="p-checkbox">
                            <div :ref="data.id" :class="['p-checkbox-box', selectedPermissionIds[data.id] ? 'p-highlight' : '' ]" @click="changeRole($event, data.id)" style="z-index: 1;">
                                <span :class="['p-checkbox-icon', selectedPermissionIds[data.id] ? 'pi pi-check' : '' ]"></span>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column header="Код" field="attributes.name" headerStyle="width: 15%" />
                <Column header="Описание" field="attributes.description" />
                <template #paginatorLeft>
                    <div class="p-col-6"></div>
                </template>
            </DataTable>
        </div>
        <div class="edit-form__actions">
            <Button class="p-button p-mr-3" :disabled="!roleIdentificator" label="Сохранить" @click="onSubmit" />
            <Button class="p-button-outlined p-mr-2" label="Отменить" @click="cancelAction" />
        </div>
        <Dialog :visible.sync="noRightsShow" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex" v-show="selectedPermissions.length === 0">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы не присвоили для роли ни одного разрешения</span>
            </div>
            <div class="p-dialog-content p-ai-center p-d-flex" v-for="error in showErrors(v$.roleIdentificator)" :key="error.$uid">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>{{ error.$message }}</span>
            </div>

            <template #footer>
                <Button class="p-button p-component p-button-text" type="button" @click="noRightsShow = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Понятно</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
        <Dialog :visible.sync="unsavedChangesShow" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы вносили изменения, и не сохранили их. При уходе со старицы все не сохраненные данные будут утеряны.</span>
            </div>

            <template #footer>
                <Button class="p-button p-button-text p-button-danger" @click="abortChanges">
                    <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label">Удалить данные и покинуть</span>
                </Button>
                <Button class="p-button p-component p-button-text" type="button" @click="unsavedChangesShow = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Вернуться к заполнению</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { createNewRole, getAllPermissions, getRoleByName, patchRole } from '@/api/role';
import { requestToastHandler } from '@/main/mixins';

import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { ROLES_FORM_TITLES } from '@/constants/roles';
export default {
    name: 'newRole',
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    props: {
        roleId: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        allPermissionsProp: {
            get() {
                return this.allPermissionsSelected;
            },
            set(v) {
                if (v) {
                    this.allPermissions.forEach((permission) => {
                        if (!this.selectedPermissionIds[permission.id] && this.$refs[permission.id]) {
                            this.$refs[permission.id].classList.toggle('p-highlight');
                            this.$refs[permission.id].lastChild.classList.toggle('pi');
                            this.$refs[permission.id].lastChild.classList.toggle('pi-check');
                            this.countSelected++;
                        }
                        this.selectedPermissionIds[permission.id] = true;
                    });
                }
                else {
                    this.allPermissions.forEach((permission) => {
                        if (this.selectedPermissionIds[permission.id] && this.$refs[permission.id]) {
                            this.$refs[permission.id].classList.toggle('p-highlight');
                            this.$refs[permission.id].lastChild.classList.toggle('pi');
                            this.$refs[permission.id].lastChild.classList.toggle('pi-check');
                            this.countSelected--;
                        }
                        this.selectedPermissionIds[permission.id] = false;
                    });
                }
            },
        },
        allPermissionsSelected() {
            return this.countSelected === this.allPermissions.length;
        },
        isEditMode() {
            return !!this.roleId;
        },
        formTitle() {
            return this.isEditMode ? this.editTitle : this.createTitle;
        },
    },
    data() {
        this.editTitle = ROLES_FORM_TITLES.EDIT;
        this.createTitle = ROLES_FORM_TITLES.CREATE;
        return {
            selectedPermissionIds: [],
            selectedPermissions: [],
            showPermissions: [],
            roleDesc: '',
            roleIdentificator: '',
            error: '',
            allPermissions: [],
            checkedPermissions: [],
            submitted: false,
            unsavedChangesShow: false,
            noRightsShow: false,
            leaveRoute: null,
            activeTabIdx: [true, false],
            totalRecords: null,
            countSelected: 0,
            tabs: [
                {
                    label: 'Основное',
                },
                {
                    label: 'Права',
                },
            ],
        };
    },
    validations() {
        const roleIdMask = () => /^[a-zA-Z0-9-_]{4,}$/.test(this.roleIdentificator);
        return {
            roleIdentificator: {
                required: { ...required, $message: 'Поле обязательно для заполнения' },
                roleIdMask: helpers.withMessage('Допустимы строчные и прописные латинские буквы, цифры, без спецсимволов, минимум 4', roleIdMask),
            },
        };
    },
    methods: {
        allPermissionValues() {
            return this.allPermissions.map((i) => i.id);
        },
        cancelAction() {
            window.history.back();
        },
        changeRole(event, permissionId) {
            let value = this.selectedPermissionIds[permissionId];
            if (value) {
                this.countSelected--;
            } else {
                this.countSelected++;
            }
            event.target.parentNode.classList.toggle('p-highlight');
            event.target.classList.toggle('pi');
            event.target.classList.toggle('pi-check');
            this.selectedPermissionIds[permissionId] = !value;
        },

        async onSubmit() {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.selectedPermissions = [];
                this.allPermissions.forEach((permission) => {
                    if (this.selectedPermissionIds[permission.id]) {
                        this.selectedPermissions.push({ id: permission.id, type: 'permission' });
                    }
                });
                if (this.selectedPermissions.length === 0) {
                    this.noRightsShow = true;
                    return;
                }
                const dataToServer = {
                    data: {
                        type: 'role',
                        id: this.roleIdentificator,
                        attributes: {
                            name: this.roleIdentificator,
                            description: this.roleDesc,
                        },
                        relationships: {
                            permissions: {
                                data: this.selectedPermissions,
                            },
                        },
                    },
                };
                try {
                    this.$emit('loadingChange', true);
                    this.isEditMode ? await patchRole(dataToServer, this.roleId) : await createNewRole(dataToServer);

                    this.v$.$anyDirty = false;
                    this.$toast.add({
                        severity: 'success',
                        summary: !this.isEditMode ? 'Роль успешно добавлена' : 'Изменения сохранены',
                        life: '2200',
                    });
                } catch (error) {
                    this.$requestError(error.message);
                    throw new Error(error.message)
                } finally {
                    this.$emit('loadingChange', false);
                }
            } else {
                this.noRightsShow = true;
            }
        },

        abortChanges() {
            this.v$.$anyDirty = false;
            this.$router.push(this.leaveRoute);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },

    async created() {
        this.$emit('loadingChange', true);
        try {
            const {
                data,
                meta: {
                    pagination: { total },
                },
            } = await getAllPermissions();
            if (data.message) {
                this.$requestError(data.message);
                return;
            }
            this.allPermissions = data;
            this.totalRecords = total;
            if (this.roleId) {
                const { data } = await getRoleByName(this.roleId);
                const {
                    attributes: { name, description },
                    relationships: {
                        permissions: { data: permissions },
                    },
                } = data;
                this.roleIdentificator = name;
                this.roleDesc = description;

                this.allPermissions.forEach((permission) => {
                    this.selectedPermissionIds[permission.id] = !!permissions.find((perm) => perm.id === permission.id );
                    if (this.selectedPermissionIds[permission.id]) {
                        this.countSelected++;
                    }
                });
            }
        } catch (error) {
            this.$requestError(error.message);
        } finally {
            this.$emit('loadingChange');
        }
    },
    beforeRouteLeave(to, from, next) {
        this.leaveRoute = to.fullPath;
        if (this.v$.$anyDirty) {
            // this.unsavedChangesShow = true;
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.onSubmit(),
                beforeClose: next
            })
            return;
        }
        next();
    },
};
</script>

<style lang='scss' scoped>
.edit-form {
    height: 100%;
    display: grid;
    grid-template-rows: min-content min-content 1fr min-content;
    &__tabs {
        ::v-deep {
            .p-tabview {
                &-nav-link {
                    display: flex;
                    justify-content: center;
                    padding: 0 0 10px 0 !important;
                    width: 5.5vw;
                    box-shadow: unset !important;
                    span {
                        font-weight: 400 !important;
                    }
                }
            }
        }
    }
    &__input {
        //width: 26.56vw;
        width: 36.328125vw;
        textarea {
            width: 100%;
        }
        &:not(:first-child) {
            margin-top: 16px;
        }
    }
}
.border_bottom {
    border-bottom: 1px solid rgb(231, 217, 217);
}
label {
    display: block;
    padding-left: 0.1rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    font-size: 12px;
}
.my-form {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 1rem;
}
.p-field-checkbox,
.p-field-radiobutton {
    margin-bottom: 0;
}
h4 {
    margin-top: 0.5rem;
}

::v-deep .p-checkbox-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    align-items: center;
    display: flex;
}
</style>
